import React, { useCallback, useEffect, useState } from 'react'
import { BsChevronDown } from 'react-icons/bs'

import axios from 'axios'

export default function GenerationPage() {

  const [Feeling, setFeeling] = useState("")
  const [Health, setHealth] = useState("")
  const [Time, setTime] = useState("15 minutes")
  const [Expertise, setExpertise] = useState("Beginner")
  const [btn, setBtn] = useState('Generate Sequence')

  const sendData = async () => {
    const data = {
      feeling: Feeling,
      health: Health,
      time: Time,
      expertise: Expertise
    }
    localStorage.setItem('userinfo', JSON.stringify(data));

    console.log(data)
    setBtn('Building Sequence...')
    setTimeout(() => {
      setBtn('Sequence Generation Started..')
    }, 6000)
    setTimeout(() => {
      setBtn('Almost There...')
    }, 14000)
    const productionURL = 'https://rohitdulal-production.up.railway.app/fetch_result'
    const url = productionURL 
    const res = axios.post(
      url,
      data
    ).then((res) => {
      setBtn('Generate Sequence')
      localStorage.setItem('myData', res.data.output.content);
      console.log(res.data.output.content)
      window.location.href = '/generatedSequence'
    }).catch((err) => {
      setBtn('Something went wrong')
      console.log(err)
    })
  }

  const customStyles = {
    control: (provided) => ({
      ...provided,
      paddingLeft: '2rem', // Adjust the padding value as needed
    }),
  };

  return (
    <div className='bg-myPink/70 min-w-screen min-h-screen py-20'>
      <div className='flex h-full w-full flex-col px-4 md:px-16 items-center'>
        <div className='h-full flex flex-col items-center w-full md:w-8/12 lg:w-3/6'>
          <div className='flex flex-row items-center justify-center mb-3'>
            <h1 className='text-myDarkBlack font-bold uppercase tracking-widest text-md md:text-2xl'>
              Yoga Instructor
            </h1>
            <span className='text-myOrange uppercase font-bold text-sm ml-4 px-2 py-1 bg-black rounded-full'>beta</span>
          </div>
          <div className='flex flex-col mt-10 w-full'>
            <div className='flex text-md text-myLightBlack mb-3 w-full'>
              How are you feeling today?
            </div>
            <div className='flex'>
              <textarea id="myTextarea" name="textareaName" rows="6" className="text-black bg-white/50 focus:outline-none f pt-3 rounded-xl pl-3 w-full" placeholder='high on energy,tired or well rested?' onChange={(e) => {
                setFeeling(e.target.value)
              }} />
            </div>
          </div>
          <div className='flex flex-col mt-7 w-full'>
            <div className='flex text-md text-myLightBlack mb-3'>
              Do you want to target any health conditions in your practice today ?
            </div>
            <div className='flex'>
              <input type="text" id="inputId" className='bg-white/50 focus:outline-none rounded-xl py-3 pl-3 w-full' name="inputName" placeholder="back-pain, stress, insomnia, anxiety, hypertension, muscle tightness" onChange={(e) => {
                setHealth(e.target.value)
              }} />
            </div>

          </div>
          <div className='flex flex-col mt-7 w-full'>
            <div className='flex text-md text-myLightBlack mb-3 w-full'>
              How would long would you like to practice?
            </div>
            <div className="relative flex w-full">
              <select className='py-3 focus:outline-none bg-white/50 rounded-xl w-full pl-3 appearance-none' onChange={(e) => {
                setTime(e.target.value)
              }}>
                <option value="15 minutes">15 mins</option>
                <option value="30 minutes">30 mins</option>
                <option value="40 minutes">40 mins</option>
              </select>
              <span className="absolute right-2 top-1/2 transform -translate-y-1/2 pointer-events-none">
                <BsChevronDown />
              </span>
            </div>
          </div>
          <div className='flex flex-col mt-7 w-full'>
            <div className='flex text-md text-myLightBlack mb-3 w-full'>
              What is your level of expertise in yoga?
            </div>
            <div className='flex w-full'>
              <div className="relative flex w-full">
                <select className='bg-white/50 focus:outline-none py-3 rounded-xl w-full pl-3 appearance-none' onChange={(e) => {
                  setExpertise(e.target.value)
                }}>
                  <option value="Beginner">Beginner</option>
                  <option value="Intermediate">Intermediate</option>
                  <option value="Advanced">Advanced</option>
                </select>
                <span className="absolute right-2 top-1/2 transform -translate-y-1/2 pointer-events-none">
                  <BsChevronDown />
                </span>
              </div>

            </div>
          </div>
          <div className='flex justify-center mt-7 w-full'>
            <button className='bg-myOrange/80 hover:bg-myOrange transition-all ease-linear w-full py-3 rounded-xl'
              onClick={sendData}
            >
              {btn}
            </button>
          </div>
        </div>
      </div>
    </div>

  )
}
